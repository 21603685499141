"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, Grid, Typography } from "antd/lib";

const { Title } = Typography;

const { useBreakpoint } = Grid;

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const clickHandler = () => {
    if (typeof window === "undefined") return;

    window.location.reload();
  };

  return (
    <Flex gap={24} vertical justify="center" align="center">
      <Title level={1} style={{ textAlign: "center" }}>
        {t("common.messages.somethingWentWrong")}
      </Title>

      <Button type="primary" onClick={clickHandler} style={{ width: screens.sm ? "auto" : "100%" }}>
        {t("common.actions.reload")}
      </Button>
    </Flex>
  );
}
